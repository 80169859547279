import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";


const Categoria = () => import("@/components/Categoria.vue")
const Categorias = () => import("@/components/Categorias.vue")
const ByBusiness = () => import("@/components/ByBusiness.vue")
const Grupo = () => import("@/components/Group.vue")
const Groups = () => import("@/components/Groups.vue")
const GroupsComponent = () => import("@/components/GroupsComponent.vue")
// const GrupoTeste = () => import("@/components/GroupTeste.vue")
const Login = () => import("@/views/LoginView.vue")
const Register = () => import("@/views/RegisterView.vue")
const Cart = () => import("@/components/Cart.vue")
const Checkout = () => import("@/components/Checkout.vue")
const ThankYou = () => import("@/components/ThankYou.vue")
const UserData = () => import("@/components/UserDashboard/UserData.vue")
const UserCompras = () => import("@/components/UserDashboard/UserCompras.vue")
const UserDetalhesCompra = () => import("@/components/UserDashboard/UserDetalhesCompra.vue")
const UserFavorite = () => import("@/components/UserDashboard/UserFavorite.vue")
const SpecialProjects = () => import("@/components/specialProjects/Main.vue")
//PÁGINAS INSTITUCIONAIS
const ResellerCategories = () => import("@/components/Institucional/ResellerCategories.vue")
const PageAboutUs = () => import("@/components/Institucional/AboutUs.vue")
const PageHelpCenter = () => import("@/components/Institucional/HelpCenter.vue")
const PagePolicies = () => import("@/components/Institucional/Policies.vue")
const PageGlossario = () => import("@/components/Institucional/Glossario.vue")
const PageVendasCorporativas = () => import("@/components/Institucional/VendasCorporativas.vue")
const PageSejaFornecedor = () => import("@/components/Institucional/SejaFornecedor.vue")
const PageTrabalheConosco = () => import("@/components/Institucional/TrabalheConosco.vue")
const PageSejaRevendedor = () => import("@/components/Institucional/SejaRevendedor.vue")
const PageEntregaPersonalizada = () => import("@/components/Institucional/EntregaPersonalizada.vue")
const PageDownloads = () => import("@/components/Institucional/Downloads.vue")
const PageArtCreation = () => import("@/components/Institucional/CriacaoDeArte.vue")
const PageContato = () => import("@/components/Institucional/Contato.vue")
const PageArtPolices = () => import("@/components/Institucional/ArtPolices.vue")
const PageFileHandle = () => import("@/components/Institucional/FileHandle.vue")
const PageCupomNewUser = () => import("@/components/Institucional/CupomNewUser.vue")
const PageNovoPadrao = () => import("@/components/Institucional/NovoPadrao.vue")
const PagePickupPoint = () => import("@/components/Institucional/PickUpPoints.vue")
const OurStores = () => import("@/components/Institucional/OurStores.vue")
const Gabaritos = () => import("@/views/Institucional/GabaritosView.vue")
const Web2print = () => import("@/components/PitchPrint.vue")
const SiteMap = () => import("@/components/SiteMap.vue")
const NotFound = () => import("@/components/NotFound.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      topheader: false,
      navigation: false,
      sitemap: false,
    },
  },
  {
    path: "/login/:redirect",
    name: "loginredirect",
    component: Login,
    meta: {
      topheader: false,
      navigation: false,
      sitemap: false,
    },
  },
  {
    path: "/cadastro",
    name: "register",
    component: Register,
    meta: {
      topheader: false,
      navigation: false,
      sitemap: false,
    },
  },
  {
    path: "/categoria/:categoryId/:categoryName",
    name: "categoria",
    component: Categoria,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
    beforeEnter: (to, from, next) => {
      // Sanitize the categoryName parameter
      const sanitizedCategoryName = to.params.categoryName
        .normalize("NFD") // Decompose combined letters into base letters + diacritics
        .replace(/[\u0300-\u036f]/g, "") // Remove diacritic marks
        .replace(/ç/g, "c") // Replace specific letters like "ç"
        .replace(/[^a-z0-9\s-]/gi, "") // Remove any remaining special characters
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .toLowerCase(); // Convert to lowercase
  
      // Redirect if the sanitized name differs from the original
      if (sanitizedCategoryName !== to.params.categoryName) {
        next({
          name: "categoria",
          params: { 
            categoryId: to.params.categoryId, 
            categoryName: sanitizedCategoryName 
          },
          query: to.query,
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/categorias",
    name: "categorias",
    component: Categorias,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
  },
  {
    path: "/para-seu-negocio/:categoryId/:categoryName",
    name: "seunegocio",
    component: ByBusiness,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
    beforeEnter: (to, from, next) => {
      // Sanitize the categoryName parameter
      const sanitizedCategoryName = to.params.categoryName
        .normalize("NFD") // Decompose combined letters into base letters + diacritics
        .replace(/[\u0300-\u036f]/g, "") // Remove diacritic marks
        .replace(/ç/g, "c") // Replace specific letters like "ç"
        .replace(/[^a-z0-9\s-]/gi, "") // Remove any remaining special characters
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .toLowerCase(); // Convert to lowercase
  
      // Redirect if the sanitized name differs from the original
      if (sanitizedCategoryName !== to.params.categoryName) {
        next({
          name: "seunegocio",
          params: { 
            categoryId: to.params.categoryId, 
            categoryName: sanitizedCategoryName 
          },
          query: to.query,
        });
      } else {
        next();
      }
    },
  },
  {
    //path: "/produto/:groupName",
    path: "/produto/:groupId/:groupName",
    name: "produto",
    component: Grupo,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
    beforeEnter: (to, from, next) => {
      // Sanitize the categoryName parameter
      const sanitizedGroupName = to.params.groupName
        .normalize("NFD") // Decompose combined letters into base letters + diacritics
        .replace(/[\u0300-\u036f]/g, "") // Remove diacritic marks
        .replace(/ç/g, "c") // Replace specific letters like "ç"
        .replace(/[^a-z0-9\s-]/gi, "") // Remove any remaining special characters
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .toLowerCase(); // Convert to lowercase
  
      // Redirect if the sanitized name differs from the original
      if (sanitizedGroupName !== to.params.groupName) {
        next({
          name: "produto",
          params: { 
            groupId: to.params.groupId, 
            groupName: sanitizedGroupName 
          },
          query: to.query,
        });
      } else {
        next();
      }
    },
  },
  {
    //path: "/produto/:groupName",
    path: "/todos-os-produtos",
    name: "produtos",
    component: Groups,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
  },
  {
    path: "/busca/:groupName",
    name: "searchResult",
    component: GroupsComponent,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
  },
  {
    path: "/carrinho",
    name: "cart",
    component: Cart,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
  },
  {
    path: "/finalizar-compra",
    name: "checkout",
    component: Checkout,
    meta: {
      topheader: false,
      navigation: false,
      sitemap: false,
    },
  },
  {
    path: "/obrigado",
    name: "thankyou",
    component: ThankYou,
    props: true,
    meta: {
      topheader: false,
      navigation: false,
      sitemap: false,
    },
  },
  {
    path: "/minha-conta/meus-dados",
    name: "userdata",
    component: UserData,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
  },
  {
    path: "/minha-conta/compras",
    name: "usercompras",
    component: UserCompras,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
  },
  {
    path: "/minha-conta/detalhes-da-compra/:purchaseId",
    name: "userdetalhescompra",
    component: UserDetalhesCompra,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
  },
  {
    path: "/minha-conta/favoritos",
    name: "userfavorites",
    component: UserFavorite,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
  },
  {
    path: "/institucional/nossas-categorias-de-revenda",
    name: "resellercategories",
    component: ResellerCategories,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Nossas categorias de revenda"
    },
  },
  {
    path: "/institucional/sobre-nos",
    name: "aboutus",
    component: PageAboutUs,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Sobre Nós"
    },
  },
  {
    path: "/institucional/novo-padrao",
    name: "novopadrao",
    component: PageNovoPadrao,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Novo Padrão"
    },
  },
  {
    path: "/institucional/central-de-ajuda/:tab",
    name: "helpcenter",
    component: PageHelpCenter,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
      sitemapName: "Central de Ajuda"
    },
  },
  {
    path: "/institucional/politicas",
    name: "policies",
    component: PagePolicies,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Políticas"
    },
  },
  {
    path: "/institucional/glossario",
    name: "glossario",
    component: PageGlossario,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Glossário"
    },
  },
  {
    path: "/institucional/vendas-corporativas",
    name: "vendascorporativas",
    component: PageVendasCorporativas,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Vendas Corporativas"
    },
  },
  {
    path: "/institucional/seja-um-fornecedor",
    name: "sejafornecedor",
    component: PageSejaFornecedor,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Seja um fornecedor"
    },
  },
  {
    path: "/institucional/faca-parte-da-equipe",
    name: "trabalheconosco",
    component: PageTrabalheConosco,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Faça parte da nossa equipe"
    },
  },
  {
    path: "/institucional/seja-um-revendedor",
    name: "sejarevendedor",
    component: PageSejaRevendedor,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Seja um revendedor"
    },
  },
  {
    path: "/institucional/entrega-personalizada-para-seu-cliente",
    name: "entregapersonalizada",
    component: PageEntregaPersonalizada,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Entrega Personalizada para o seu cliente"
    },
  },
  {
    path: "/institucional/downloads",
    name: "downloads",
    component: PageDownloads,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Downloads"
    },
  },
  {
    path: "/institucional/criacao-arte",
    name: "artCreation",
    component: PageArtCreation,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Criação de Arte"
    },
  },
  {
    path: "/institucional/nossas-lojas",
    name: "ourstores",
    component: OurStores,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Nossas Lojas"
    },
  },
  {
    path: "/institucional/normas-envio-arte",
    name: "artPolices",
    component: PageArtPolices,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Normas de Envio de Arte"
    },
  },
  {
    path: "/institucional/tratamento-de-arquivo",
    name: "fileHandle",
    component: PageFileHandle,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Tratamento de Arquivo"
    },
  },
  {
    path: "/institucional/cupom-novos-clientes",
    name: "cupomNewUser",
    component: PageCupomNewUser,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Cupom Novos Clientes"
    },
  },
  {
    path: "/institucional/pontos-de-retirada",
    name: "pickuppoint",
    component: PagePickupPoint,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: true,
      sitemapName: "Pontos de Retirada"
    },
  },
  {
    path: "/projetos-especiais",
    name: "specialprojects",
    component: SpecialProjects,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
  },
  {
    path: "/contato",
    name: "contact",
    component: PageContato,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
  },
  {
    path: "/gabaritos",
    name: "gabaritos",
    component: Gabaritos,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
  },
  {
    path: "/web2print",
    name: "web2print",
    component: Web2print,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
  },
  {
    path: "/mapa-do-site",
    name: "sitemap",
    component: SiteMap,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
  },
  {
    path: "/:notFound",
    component: NotFound,
    meta: {
      topheader: true,
      navigation: true,
      sitemap: false,
    },
  }

];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // If the route has a savedPosition (e.g., back/forward navigation)
    // if (savedPosition) {
    //   return savedPosition
    // } else {
    //   // Scroll to the top of the page
    //   return { top: 0 }
    // }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ top: 0, behavior: 'smooth' });
      }, 300); // Adjust delay for desired transition speed
    });
  }
});

export {routes};
export default router;